import profileService from "../../services/user-data"

export default {
    data() {
        return {
            profile: this.$store.state.user,
            emailOTPsend: false,
            phoneOTPsend: false,
            formValidations: {
                first_name: v => !!v || "First Name is required",
                last_name: v => !!v || "Last Name is required",
            },
            emailFormValidations: {
                email: v => v ? (/.+@.+/.test(v) || "E-mail must be valid") : "E-mail is required",
                otp: v => {
                    if(this.emailOTPsend) {
                        return v ? (/^(?=.{6,})/.test(v) || 'OTP must be 6 digits') : "OTP is required"
                    }
                    return true;
                }
            },
            phoneFormValidations: {
                phone: v => v ? (/^\d+$/.test(v) || 'Phone must be valid') : "Phone is required",
                otp: v => {
                    if(this.phoneOTPsend) {
                        return v ? (/^(?=.{6,})/.test(v) || 'OTP must be 6 digits') : "OTP is required"
                    }
                    return true;
                }
            }
        };
    },
    mounted() {
        this.email = this.profile.email;
        this.phone = this.profile.phone;
    },
    methods: {
        updateProfile() {
            this.showLoader(true);
            this.$popper.wait("updating...");
            profileService.updateProfile(this.profile.id, {
                'first_name' : this.profile.first_name,
                'last_name': this.profile.last_name
            }).then(response => {
                this.hideLoader();
                this.$store.bus.emit('user', this.profile);
                this.$popper.success(response.data.message)
            })
        },
        updateEmail() {
            this.showLoader(true);
            var data = { 'email' : this.email };
            if (!this.emailOTPsend) {
                this.$popper.wait("Sending OTP...");
                profileService.emailPhoneSendOtp(data).then(response => {
                    this.hideLoader();
                    this.$popper.success(response.data.message)
                    this.emailOTPsend = true;
                })
            } else {
                this.$popper.wait("Updating...");
                data['otp'] = this.email_otp;
                profileService.emailPhoneUpdate(data).then(response => {
                    this.hideLoader();
                    this.$popper.success(response.data.message);
                    this.profile.email = this.email;
                    this.$store.bus.emit('user', this.profile);
                    this.emailOTPsend = false;
                })
            }
        },
        resendEmailOTP() {
            this.showLoader(true);
            this.$popper.wait("Sending OTP...");
            profileService.emailPhoneSendOtp({
                'email' : this.email,
            }).then(response => {
                this.hideLoader();
                this.$popper.success(response.data.message)
            })
        },
        updatePhone() {
            this.showLoader(true);
            var data = { 'phone' : this.phone };
            this.$popper.wait("Processing...");
            if (!this.phoneOTPsend) {
                this.$popper.wait("Sending OTP...");
                profileService.emailPhoneSendOtp(data).then(response => {
                    this.hideLoader();
                    this.$popper.success(response.data.message)
                    this.phoneOTPsend = true;
                })
            } else {
                this.$popper.wait("Updating...");
                data['otp'] = this.phone_otp;
                profileService.emailPhoneUpdate(data).then(response => {
                    this.hideLoader();
                    this.$popper.success(response.data.message);
                    this.profile.phone = this.phone;
                    this.$store.bus.emit('user', this.profile);
                    this.phoneOTPsend = false;
                })
            }
        },
        resendPhoneOTP() {
            this.showLoader(true);
            this.$popper.wait("Sending OTP...");
            profileService.emailPhoneSendOtp({
                'phone' : this.phone,
            }).then(response => {
                this.hideLoader();
                this.$popper.success(response.data.message)
            })
        }
    }
}