<app-header>
    <a href="#" class="header-icon header-icon-1 d-flex align-items-center justify-content-center" v-if="$store.state.user.user_type != 'super_admin'">
        <i class="far fa-user font-22 primary-alt-light-text"></i>
    </a>
    <h1 class="header-title text-capitalize" style="letter-spacing:0.5px;">Profile</h1>
    <router-link :to="{name:'profile-edit'}" v-if="$store.state.user.user_type != 'super_admin'"
        class="header-icon header-icon-2">
        <i class="far fa-edit"></i>
    </router-link>
</app-header>
<div class="page-title-clear"></div>

<div class="page-content">
    <div class="card card-style profile-personalDetailCard mt-3" v-if="profileDetail">
        <div class="card-body ms-3 me-3 p-0 d-flex flex-column justify-content-evenly">

            <div class="profile-details mb-3 mt-3">
                <div class="profile-detailIconContainer">
                    <i class="fas fa-user profile-businessIcon"></i>
                </div>
                <div class="profile-detailInfo">
                    <h5 class="profile-personalDetail mb-0">
                        {{ profileDetail.full_name ? profileDetail.full_name : '-' }}
                    </h5>
                </div>
            </div>

            <div class="profile-details mb-3">
                <div class="profile-detailIconContainer">
                    <i class="fas fa-building profile-businessIcon"></i>
                </div>
                <div class="profile-detailInfo">
                    <h5 class="profile-personalDetail mb-1">
                        {{ profileDetail.business_name ? profileDetail.business_name : '' }}
                    </h5>
                    <div class="mb-0">
                        <div class="col-12 profile-letterSpacing" v-if="profileDetail.address">
                            {{ profileDetail.address ? profileDetail.address : "" }} -
                            {{ profileDetail.zipcode ? profileDetail.zipcode : "" }}
                        </div>
                        <div class="col-12 profile-letterSpacing" v-if="profileDetail.city && profileDetail.state">
                            {{ profileDetail.city ? profileDetail.city : "" }} -
                            {{ profileDetail.state ? profileDetail.state : "" }}&nbsp;
                            <span class="color-theme profile-letterSpacing" v-if="profileDetail.country_emoji">
                                ( {{ profileDetail.country_emoji ? profileDetail.country_emoji : "" }}
                                {{ profileDetail.country ? profileDetail.country : "" }} )
                            </span>
                        </div>
                        <div class="col-12 profile-letterSpacing" v-if="profileDetail.business_website">
                            {{ profileDetail.business_website ? profileDetail.business_website : "" }}
                        </div>
                    </div>
                </div>
            </div>
            <div class="profile-details mb-3">
                <div class="profile-detailIconContainer">
                    <i class="fas fa-envelope profile-userIcon"></i>
                </div>
                <div class="profile-detailInfo">
                    <h5 class="mb-0">
                        {{ profileDetail.email && profileDetail.email.length > 20 ? `${profileDetail.email.slice(0,20)}...` : profileDetail.email }}
                    </h5>
                </div>
            </div>

            <div class="profile-details mb-3">
                <div class="profile-detailIconContainer">
                    <i class="fas fa-phone profile-userIcon"></i>
                </div>
                <div class="profile-detailInfo">
                    <h5 class="mb-0">
                        {{ profileDetail.phone ? profileDetail.phone : '-' }}
                    </h5>
                </div>
            </div>
        </div>
    </div>

    <div class="profile-cardTitleContainer mb-3 ms-3" v-if="$store.state.user.user_type != 'super_admin'">
        <div class="profile-businessDetailIconWrapper">
            <div class="profile-IconContainer">
                <i class="fas fa-info profile-businessDetailIcon"></i>
            </div>
        </div>
        <h5 class="profile-cardTitle">
            Basic Information
        </h5>
    </div>

    <!-- cluster-admin profile detail-->
    <div class="card card-style" v-if="$store.state.user.user_type == 'cluster_admin'">
        <div class="content">
            <div class="row mb-3">
                <div class="col-5">Country</div>
                <div class="col-7 color-theme">{{profileDetail.country ? profileDetail.country.emoji : ""}} &nbsp;
                    {{profileDetail.country ? profileDetail.country.name : ""}}</div>
                <div class="col-5">RegisteredOn</div>
                <div class="col-7 color-theme">{{profileDetail.created_at ? localDateTime(profileDetail.created_at) :
                    ''}}</div>
            </div>
            <h4 class="col-12 font-600 mb-2">Connected Regions</h4>
            <div class="row mb-2" v-if="profileDetail.state">
                <div class="col-5">States</div>
                <div class="col-7 color-theme">
                    {{ profileDetail.regions_name.state ? profileDetail.regions_name.state : '-' }}
                </div>
            </div>
            <div class="row mb-2" v-if="profileDetail.city">
                <div class="col-5">Cities</div>
                <div class="col-7 color-theme">
                    {{ profileDetail.regions_name.city ? profileDetail.regions_name.city : '-' }}
                </div>
            </div>
            <div class="row mb-2" v-if="profileDetail.zone">
                <div class="col-5">Zones</div>
                <div class="col-7 color-theme">
                    {{ profileDetail.regions_name.zone ? profileDetail.regions_name.zone : '-' }}
                </div>
            </div>
        </div>
    </div>

    <!-- partner-admin profile detail -->
    <div class="card card-style" v-if="$store.state.user.user_type == 'partner_admin'">
        <div class=" content">
            <div class="row mb-2">
                <div class="col-5">Country</div>
                <div class="col-7 color-theme">{{profileDetail.country ? profileDetail.country.emoji : ""}} &nbsp;
                    {{profileDetail.country ? profileDetail.country.name : ""}}</div>
                <div class="col-5">RegisteredOn</div>
                <div class="col-7 color-theme">{{ localDateTime(profileDetail.created_at)}}</div>
            </div>
            <div v-if="profileDetail.partners_count">
                <h2 class="col-12 mt-4">Partners ({{ profileDetail.partners_count}})</h2>
                <div class="col mt-2 font-15" v-for="record in profileDetail.partners">
                    <span class="color-theme">{{record.business_name + (record.business_name ? " - " : "")}}</span>
                    <span>{{record.full_name}}</span>
                </div>
            </div>
        </div>
    </div>

    <!-- partner profile detail -->
    <div class="card card-style" v-if="$store.state.user.user_type == 'partner'">
        <div class=" content">
            <div class="row mb-0">
                <div class="col-6 font-600 profile-letterSpacing" v-if="profileDetail.partner_type">Partner Type</div>
                <div class="col-6 profile-letterSpacing" v-if="profileDetail.partner_type">{{ profileDetail.partner_type
                    ?
                    profileDetail.partner_type : "" }}</div>
                <div class="col-6 font-600 profile-letterSpacing" v-if="profileDetail.referral_code">Referral Code</div>
                <div class="col-6 profile-letterSpacing" v-if="profileDetail.referral_code">{{
                    profileDetail.referral_code ?
                    profileDetail.referral_code : "" }}</div>
                <div class="col-6 font-600 profile-letterSpacing" v-if="profileDetail.parent">Parent Partner</div>
                <div class="col-6 profile-letterSpacing" v-if="profileDetail.parent">
                    <router-link :to="{'name': 'partner-detail', params: {'id' : profileDetail.parent.id}}" href="#">
                        {{ profileDetail.parent ? profileDetail.parent.full_name : "" }}
                    </router-link>
                </div>
                <div class="col-6 font-600 profile-letterSpacing">Registered At</div>
                <div class="col-6 profile-letterSpacing">{{ localDateTime(profileDetail.created_at) }}</div>
            </div>
        </div>
    </div>

    <div v-for="(value, key) in profileDetail.values">

        <div class="profile-cardTitleContainer mb-3 ms-3" v-if="getLabel(key) === 'Business Detail'">
            <div class="profile-businessDetailIconWrapper">
                <div class="profile-IconContainer">
                    <i class="fas fa-briefcase profile-businessDetailIcon"></i>
                </div>
            </div>
            <h5 class="profile-cardTitle">
                {{ getLabel("business_detail")}}
            </h5>
        </div>
        <div class="profile-cardTitleContainer mb-3 ms-3" v-if="getLabel(key) === 'Business KYC Details'">
            <div class="profile-businessDetailIconWrapper">
                <div class="profile-IconContainer">
                    <i class="fas fa-id-card profile-businessDetailIcon"></i>
                </div>
            </div>
            <h5 class="profile-cardTitle">
                {{ getLabel("business_kyc_details")}}
            </h5>
        </div>
        <div class="profile-cardTitleContainer mb-3 ms-3" v-if="getLabel(key) === 'Business Bank Account Information'">
            <div class="profile-businessDetailIconWrapper">
                <div class="profile-IconContainer">
                    <i class="fas fa-landmark profile-businessDetailIcon"></i>
                </div>
            </div>
            <h5 class="profile-cardTitle">
                {{ getLabel("business_bank_account_information") }}
            </h5>
        </div>

        <div class="card card-style" v-if="$store.state.user.user_type == 'partner'">
            <div class=" content">
                <div v-for="(fieldValue, fieldKey) in value" class="row mb-0">
                    <div class="col-6 font-600 profile-letterSpacing lh-lg">
                        {{ fieldKey ? getLabel(fieldKey) : "" }}
                    </div>
                    <div v-if="fieldValue.type == 'input_file'" class="col-6 lh-lg">
                        <a :href="fieldValue.value ? fieldValue.value : ''" target="_blank">
                            <i class="fas fa-file-alt"></i>
                        </a>
                    </div>
                    <div v-else class="col-6 profile-letterSpacing lh-lg">
                        {{ fieldValue.value ? fieldValue.value : "" }}
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<app-footer />