<app-header menu="no">
    <h1 class="header-title text-capitalize" style="letter-spacing:0.5px;">Edit Profile</h1>
    <a href="#" @click="$router.go(-1)" class="header-icon header-icon-1">
        <i class="fa fa-arrow-left"></i>
    </a>
</app-header>

<div class="page-title-clear"></div>

<div class="page-content">
    <div class="card card-style">
        <div class="content">
            <Form novalidate @submit="updateProfile" :validation-schema="formValidations" v-slot="{ errors }" autocomplete="off">
                
                <form-control class="validate-field input-style-active" :class="{'is-invalid' : errors.first_name}">
                    <Field as="input" type="text" name="first_name" v-model="profile.first_name" class="form-control letterSpacing" id="first_name"></Field>
                    <label for="first_name" class="color-primary-dark font-13 font-600 font-13 font-600">First Name</label>
                    <em>(required)</em>
                    <template v-slot:error>{{errors.first_name}}</template>
                </form-control>

                <form-control class="validate-field input-style-active" :class="{'is-invalid' : errors.last_name}">
                    <Field as="input" type="text" name="last_name" v-model="profile.last_name" class="form-control letterSpacing" id="last_name"></Field>
                    <label for="last_name" class="color-primary-dark font-13 font-600 font-13 font-600">Last Name</label>
                    <em>(required)</em>
                    <template v-slot:error>{{errors.last_name}}</template>
                </form-control>

                <button class="btn btn-l btn-ripple font-600 font-13 primary-light-bg letterSpacing rounded-s w-100">Update</button>
            </Form>
        </div>
    </div>

    <div class="card card-style">
        <div class="content">
            <h6 class="mb-4">Update Email</h6>
            <Form novalidate @submit="updateEmail" :validation-schema="emailFormValidations" v-slot="{ errors }" autocomplete="off">
                
                <form-control class="validate-field input-style-active"  :class="{'is-invalid' : errors.email}">
                    <Field as="input" type="email" name="email" :disabled="emailOTPsend" v-model="email" class="form-control letterSpacing" id="email"></Field>
                    <label for="email" class="color-primary-dark font-13 font-600 font-13 font-600">Email</label>
                    <em>(required)</em>
                    <template v-slot:error>{{errors.email}}</template>
                </form-control>
                <a href="#" class="" @click="resendEmailOTP" v-if="emailOTPsend">Resend OTP</a>
                <form-control class="validate-field input-style-active mt-3" :class="{'is-invalid' : errors.otp}" v-if="emailOTPsend">
                    <Field as="input" type="otp" name="otp" v-model="email_otp" class="form-control letterSpacing" id="otp"></Field>
                    <label for="otp" class="color-primary-dark font-13 font-600 font-13 font-600">OTP</label>
                    <em>(required)</em>
                    <template v-slot:error>{{errors.otp}}</template>
                </form-control>

                <button type="submit" class="btn btn-l btn-ripple font-600 font-13 primary-light-bg letterSpacing rounded-s w-100" :disabled="email == profile.email">{{ emailOTPsend ? 'Update' : 'Send OTP' }}</button>
            </Form>
        </div>
    </div>

    <div class="card card-style">
        <div class="content">
            <h6 class="mb-4">Update Phone</h6>
            <Form novalidate @submit="updatePhone" :validation-schema="phoneFormValidations" v-slot="{ errors }" autocomplete="off">
                
                <form-control class="validate-field input-style-active"  :class="{'is-invalid' : errors.phone}">
                    <Field as="input" type="phone" name="phone" :disabled="phoneOTPsend" v-model="phone" class="form-control letterSpacing" id="phone"></Field>
                    <label for="phone" class="color-primary-dark font-13 font-600 font-13 font-600">Phone</label>
                    <em>(required)</em>
                    <template v-slot:error>{{errors.phone}}</template>
                </form-control>
                <a href="#" class="" @click="resendPhoneOTP" v-if="phoneOTPsend">Resend OTP</a>
                <form-control class="validate-field input-style-active mt-3" :class="{'is-invalid' : errors.otp}" v-if="phoneOTPsend">
                    <Field as="input" type="otp" name="otp" v-model="phone_otp" class="form-control letterSpacing" id="otp"></Field>
                    <label for="otp" class="color-primary-dark font-13 font-600 font-13 font-600">OTP</label>
                    <em>(required)</em>
                    <template v-slot:error>{{errors.otp}}</template>
                </form-control>

                <button type="submit" class="btn btn-l btn-ripple font-600 font-13 primary-light-bg letterSpacing rounded-s w-100" :disabled="phone == profile.phone">{{ emailOTPsend ? 'Update' : 'Send OTP' }}</button>
            </Form>
        </div>
    </div>
</div>