import userData from "../../services/user-data";
export default {
    data() {
        return {
            profileDetail: {}
        };
    },
    mounted() {
        this.showLoader(true);
        userData.getProfileDetail().then(response => {
            this.hideLoader();
            this.profileDetail = response.data.data
        });
    },
}